import React from 'react';
import ErrorScreen from '../components/common/screen/errorScreen/ErrorScreen';

const NotFoundPage = (): JSX.Element => {
  return (
    <ErrorScreen
      title="ページが見つかりません"
      message="お探しのページはアドレスが間違っているか、削除された可能性があります。"
      reloadable={false}
    />
  );
};

export default NotFoundPage;
